import React, { useContext } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import useFlowManager from 'lib/hooks/useFlowManager';
import { respondTo } from 'styles/helper';
import color from 'styles/colors';
import CheckMark from '../Icons/CheckMark';
import { ACCOUNT_COOKIE_NAME_FOX, ANONYMOUS, COOKIE_CODE_INFO } from 'constants/index';
import { AppContext } from 'context/GlobalContextProvider';
import { Button, Link } from '../index';

const string = {
  profileSignedIn: "You're signed in!",
  profileSignedUp: 'Your profile has been created!',
  mainTitle: 'Select TV Provider',
};

const Messages = ({
  isNationAuthSuccess = false,
}: {
  isNationAuthSuccess?: boolean;
}): JSX.Element | null => {
  const [cookies] = useCookies([ACCOUNT_COOKIE_NAME_FOX, COOKIE_CODE_INFO]);
  const { isMVPD, isNew, isNation } = useFlowManager();

  const {
    state: {
      config: { siteUrl },
      isRegister: { value: isRegister },
    },
  } = useContext(AppContext);

  const mainTitle = <H1>{string.mainTitle}</H1>;

  if (isNation && isNationAuthSuccess) {
    return (
      <>
        <CheckMark />
        <Message>Thank you for signing in to Fox Nation!</Message>
        <H1>You may now return to your TV and enjoy Fox Nation content.</H1>
        <ButtonLink external href={`${siteUrl}`}>
          <Button>Home</Button>
        </ButtonLink>
      </>
    );
  }

  if (cookies[ACCOUNT_COOKIE_NAME_FOX]?.accountType !== ANONYMOUS || isNew || isRegister) {
    return (
      <>
        <CheckMark />
        <Message>{isRegister ? string.profileSignedUp : string.profileSignedIn}</Message>
        {mainTitle}
      </>
    );
  }

  if (isMVPD || cookies[COOKIE_CODE_INFO]?.isAnonymous) {
    return <>{mainTitle}</>;
  }

  return null;
};

export default Messages;

const Message = styled.p`
  color: ${color.white};
  font-size: 15px;
  font-family: Graphik, serif;
  font-weight: normal;
  letter-spacing: -0.27px;
  line-height: 20px;
  ${respondTo.bp_767`
    font-size: 20px;
    letter-spacing: -0.3px;
  `}
`;

const H1 = styled.h1`
  margin-bottom: 50px;
  font-family: Graphik, serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  ${respondTo.bp_767`
    font-size: 40px;
    letter-spacing: -0.5px;
    line-height: 45px;
    margin: 25px 0 50px;
	`}
`;

const ButtonLink = styled(Link)`
  width: 100%;
`;
