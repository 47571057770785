import styled, { css } from 'styled-components';
import color, { fox } from 'styles/colors';

const Input = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding-left: 1rem;
  border-radius: 6px;
  outline: none;
  background: ${fox.white10};
  border: none;
  color: inherit;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  &:focus:not(.error) {
    outline: 0;
    color: ${color.white};
    background: ${color.white10};
  }
`;

const AccountInputCss = css`
  max-width: 340px;
  font-weight: normal;
  text-align: left;
  color: ${color.white};
  &:focus,
  :active {
    color: ${color.white};
    &::placeholder {
      color: ${color.white};
      opacity: 0.5;
    }
  }
  &::placeholder {
    color: ${color.white};
    opacity: 0.5;
  }
`;

export { Input, AccountInputCss };
