import styled from 'styled-components';
import color, { fox, nation, sports } from 'styles/colors';
import theme from 'styles';

type ButtonType = {
  hollow?: boolean;
  kind?: string;
};

const background = theme.variants('brand', 'kind', {
  default: {
    fox: fox.bluePrimary,
    sports: sports.endeavour,
    nation: nation.hitGray,
  },
  secondary: { fox: color.white, sports: color.white, nation: color.white },
  hollow: { fox: color.transparent, sports: color.transparent, nation: color.transparent },
});

const border = theme.variants('brand', 'kind', {
  default: { fox: fox.border, sports: sports.border, nation: nation.border },
  hollow: {
    fox: `1px solid ${fox.white25}`,
    sports: `1px solid ${sports.endeavour}`,
    nation: `1px solid ${nation.hitGray}`,
  },
});

const textColor = theme.variants('brand', 'kind', {
  default: { fox: color.white, sports: color.white, nation: color.white },
  secondary: { fox: color.black, sports: color.black, nation: color.black },
});

const Button = styled.button<ButtonType>`
  font: inherit;
  background: ${background};
  color: ${({ theme }) => theme?.colors?.button.text};
  color: ${textColor};
  position: relative;
  display: flex;
  width: 100%;
  max-width: 340px;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border: ${border};
  margin: 20px auto;
  border-radius: 22px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  outline: 0;
  transition: ease all 200ms;
  &:hover {
    background: ${({ theme }) => theme?.colors?.button.background};
    color: ${({ theme }) => theme?.colors?.button.text};
    border: ${({ theme }) => theme?.colors?.button.background};
    transition: ease all 200ms;
  }
  &:disabled {
    background: ${({ theme }) => theme?.colors?.button.background};
    cursor: initial;
    opacity: 0.5;
    color: ${({ theme }) => theme?.colors?.button.disabled};
  }
`;

export default Button;
