export type ThemeValue = string | ((props: AnyObject) => string);
export type ThemeMap = { [key: string]: ThemeValue };
export type VariantMap = { [key: string]: ThemeMap };

export type ThemeSet = (props: AnyObject) => string;
export type VariantSet = (props: AnyObject) => string;

const getThemeValue = (
  name: string,
  props: AnyObject,
  values: ThemeMap,
): ThemeValue | undefined => {
  const value = props?.theme[name];

  let themeValue;

  if (typeof value === 'function') {
    if (values) {
      themeValue = values[Number(value)];
    }
  } else {
    themeValue = values[value] as string;
  }

  if (typeof themeValue === 'function') {
    return themeValue(props);
  } else {
    return themeValue;
  }
};

const theme = (name: string, values: ThemeMap) => {
  return (props: AnyObject): ThemeSet => {
    return <(props: AnyObject) => string>getThemeValue(name, props, values);
  };
};

theme.variants = (name: string, prop: string, values: VariantMap): VariantSet => {
  return (props) => {
    const variant = props[prop] && values[props[prop]];
    return variant && getThemeValue(name, props, variant);
  };
};

export default theme;
